<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex flex-column align-center">
        <div class="d-flex align-center" style="position: relative">
          <img
            :src="require(`../../assets/pokerModes/${pokerMode}.png`)"
            alt="poker mode"
            style="width: 250px"
          />
          <img
            src="../../assets/arrow.png"
            style="width: 50px; position: absolute; right: -40px"
            @click="pokerModeShow()"
            :class="{
              rotateTrue: pokerModesShow,
              rotateFalse: !pokerModesShow,
            }"
          />
        </div>
        <div v-if="pokerModesShow" class="pokerMode-container">
          <div class="pokerMode">
            <img
              v-for="(m, i) in pokerModes"
              :key="i"
              :src="require(`../../assets/pokerModes/${m}.png`)"
              :alt="m"
              style="width: 250px"
              class="pointer"
              @click="pokerModeSelect(m)"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-4" justify="center">
      <v-col
        :cols="pieChartValue.length === 4 ? 3 : 2"
        v-for="c in pieChartValue"
        :key="c.header"
        class="d-flex"
        style="width: 100%"
      >
        <DonutCharts
          :header="c.header"
          :p1="typeof c.p1 != 'undefined' ? c.p1 : 0"
          :p2="c.p2"
          :color="c.color"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="4">
        <v-row>
          <v-col cols="12"
            ><BarChart
              :modeStatistics="user?.played[pokerMode]"
              v-if="pokerMode == 'h' || pokerMode == 'o'"
          /></v-col>
          <v-col cols="12"
            ><PolarBarChart
              :modeStatistics="user?.played[pokerMode]"
              show="true"
          /></v-col>
        </v-row>
      </v-col>
      <v-col cols="8" class="d-flex">
        <div style="width: 100%">
          <div v-for="(h, i) in userStatisticsHeader" :key="i">
            <h2
              v-if="
                i < 7
                  ? true
                  : i == 7 &&
                    (pokerMode == 't' || pokerMode == 'f' || pokerMode == 'r')
              "
              :class="{ paddingYLarge: i >= 6, paddingYMedium: i < 6 }"
              style="border-bottom: 2px solid gray"
            >
              {{ h }}
            </h2>
          </div>
        </div>
        <div style="text-align: right; width: 100%">
          <div v-for="(v, i) in userStatisticsValues" :key="i">
            <div
              v-if="
                i < 7
                  ? true
                  : i == 7 &&
                    (pokerMode == 't' || pokerMode == 'f' || pokerMode == 'r')
              "
              class="paddingYMedium"
              style="
                border-bottom: 2px solid gray;
                color: rgb(169, 169, 0);
                font-size: 1.3rem;
                font-weight: 700;
              "
            >
              <div v-if="i < 6">{{ v }}</div>
              <div v-if="i >= 6 && v[0] != '-'">
                <img
                  v-for="c in v"
                  :key="c"
                  :src="require(`../../assets/cards/${c}.png`)"
                  :alt="c"
                  style="width: 2.5rem"
                  class="pointer ml-2"
                  @click="pokerModeSelect(m)"
                />
              </div>
              <div v-if="i >= 6 && v[0] == '-'">
                <img
                  v-for="c in [1, 2, 3, 4, 5]"
                  :key="c"
                  :src="require(`../../assets/cards/cardBack.png`)"
                  :alt="c"
                  style="width: 2.5rem"
                  class="pointer ml-2"
                  @click="pokerModeSelect(m)"
                />
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BarChart from "../Charts/BarChart.vue";
import PolarBarChart from "../Charts/PolarBarChart.vue";
import DonutCharts from "../Charts/DonutCharts.vue";
export default {
  name: "UserStatistics",
  components: {
    BarChart,
    PolarBarChart,
    DonutCharts,
  },
  beforeMount() {
    this.pokerModeSelect("h");
  },
  data() {
    return {
      pokerModesShow: false,
      pokerModes: [
        "h",
        "o",
        "bj",
        "t",
        "f",
        "u",
        "hb",
        "r",
        "c",
        "cs",
        "ch",
        "wc",
        "h3",
        "dt",
        "br",
      ],
      userStatisticsValues: [],
      userStatisticsHeader: [
        "Oynanan Süre",
        "Favori Masa",
        "Oynanan Eller",
        "Seviye",
        "En Büyük Pot",
        "En Büyük Jackpot",
        "En İyi El",
        "En İyi El",
      ],
      newStr: "",
      favoriteDeck: [
        "Emerald",
        "Ruby",
        "Sapphire",
        "Onyx",
        "Amethyst",
        "ELITE-Pro",
        "ELITE-Expert",
        "ELITE-Champion",
        "ELITE-Master",
        "ELITE-Legend",
        "ELITE",
      ],
      pieChartHeader: [],
      pieChartValue: [],
      pieChartColor: [
        ["#00FF00", "#546E7A"],
        ["#FF0000", "#546E7A"],
        ["#FF7F00", "#546E7A"],
        ["#0000FF", "#546E7A"],
        ["#9932CC", "#546E7A"],
        ["#FFFACD", "#546E7A"],
      ],
    };
  },
  props: {
    user: {
      type: Object,
      description: "user",
    },
    pokerMode: {
      type: String,
      description: "pokerMode",
    },
  },
  methods: {
    pokerModeShow() {
      this.pokerModesShow = !this.pokerModesShow;
    },
    pokerModeSelect(m) {
      this.pokerMode = m;
      for (let index = 0; index < this.userStatisticsHeader.length; index++) {
        this.userStatisticsValues[index] = this.setStatic(index, m);
      }
      let r = 5;
      if (m === "h" || m === "o" || m === "hb") r = 4;
      if (m === "bj" || m === "h3") r = 6;
      this.pieChartValue = [];

      for (let i = 0; i < r; i++) {
        this.pieChartValue[i] = this.setPieChart(i, m);
      }
      this.pokerModesShow = false;
    },
    setStatic(index, m) {
      switch (index) {
        case 0: {
          let day = Math.floor(this.user.played[m].t[0] / 86400);
          let hour = Math.floor((this.user.played[m].t[0] / 3600) % 24);
          let minute = Math.floor(this.user.played[m].t[0] / 60) % 60;
          let second = Math.floor(this.user.played[m].t[0] % 60);
          if (day != 0) return `${day} Gün ${hour} Saat`;
          if (hour != 0) return `${hour} Saat ${minute} Dakika`;
          if (minute != 0) return `${minute} Dkk ${second} Sn`;
          if (second != 0) return `${second} Sn`;
          return "-";
        }
        case 1:
          if (Math.max(...this.user.played[m].s) != 0)
            return this.favoriteDeck[
              this.user.played[m].s.indexOf(Math.max(...this.user.played[m].s))
            ];
          return "-";
        case 2:
          return this.numberFormat(this.user.played[m].t[1]);
        case 3:
          return this.user.played[m].l;
        case 4:
          return `$${this.numberFormat(this.user.played[m].t[3])}`;
        case 5:
          return `$${this.numberFormat(this.user.played[m].t[4])}`;
        case 6:
          return this.user.played[m].c[0].split(",");
        case 7:
          return this.user.played[m].c[1].split(",");
        default:
          break;
      }
    },

    setPieChart(i, m) {
      switch (m) {
        case "h" || "o":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Arttır"];
          break;
        case "bj":
          this.pieChartHeader = [
            "Kazan",
            "Kaybet",
            "Hot 3",
            "First 3",
            "Any Pair",
            "Bust It",
          ];
          break;
        case "t":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Pair+", "3+3"];
          break;
        case "f":
          this.pieChartHeader = [
            "Kazan",
            "Kaybet",
            "Pas",
            "Flush",
            "Straight Flush",
          ];
          break;
        case "u":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Blind", "Trips"];
          break;
        case "hb":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Bonus"];
          break;
        case "r":
          this.pieChartHeader = [
            "Kazan",
            "Kaybet",
            "Pas",
            "Lucky 3",
            "Lucky 6",
          ];
          break;
        case "c":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Ante", "Bonus"];
          break;
        case "cs":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Call", "Bonus"];
          break;
        case "ch":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Ante", "Play"];
          break;
        case "wc":
          this.pieChartHeader = ["Kazan", "Kaybet", "Pas", "Blind", "Bonus"];
          break;
        case "h3":
          this.pieChartHeader = [
            "Kazan",
            "Kaybet",
            "3 Card",
            "5 Card",
            "7 Card",
            "All Lose",
          ];
          break;
        case "dt":
          this.pieChartHeader = ["Kazan", "Kaybet", "Dragon", "Tiger", "Tie"];
          break;
        case "br":
          this.pieChartHeader = ["Kazan", "Kaybet", "Player", "Banker", "Tie"];
          break;
        default:
          break;
      }
      if (m == "bj") {
        if (i == 2)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[2],
            p2: this.user?.played[m]?.p[2],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[3],
            p2: this.user?.played[m]?.p[3],
            color: this.pieChartColor[i],
          };
        if (i == 5)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[5],
            p2: this.user?.played[m]?.p[5],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m == "u" || m == "t" || m == "f" || m == "wc") {
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[2],
            p2: this.user?.played[m]?.p[2],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "hb") {
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[5],
            p2: this.user?.played[m]?.p[5],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "r") {
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[3],
            p2: this.user?.played[m]?.p[3],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[5],
            p2: this.user?.played[m]?.p[5],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "ch") {
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[0],
            p2: this.user?.played[m]?.p[0],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "c" || m === "cs") {
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[2],
            p2: this.user?.played[m]?.p[2],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "h3") {
        if (i == 2)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[0],
            p2: this.user?.played[m]?.p[0],
            color: this.pieChartColor[i],
          };
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[2],
            p2: this.user?.played[m]?.p[2],
            color: this.pieChartColor[i],
          };
        if (i == 5)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[3],
            p2: this.user?.played[m]?.p[3],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "dt") {
        if (i == 2)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[0],
            p2: this.user?.played[m]?.p[0],
            color: this.pieChartColor[i],
          };
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[2],
            p2: this.user?.played[m]?.p[2],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      if (m === "br") {
        if (i == 2)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[0],
            p2: this.user?.played[m]?.p[0],
            color: this.pieChartColor[i],
          };
        if (i == 3)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[1],
            p2: this.user?.played[m]?.p[1],
            color: this.pieChartColor[i],
          };
        if (i == 4)
          return {
            header: this.pieChartHeader[i],
            p1: this.user?.played[m]?.wb[4],
            p2: this.user?.played[m]?.p[4],
            color: this.pieChartColor[i],
          };
        return {
          header: this.pieChartHeader[i],
          p1: this.user?.played[m]?.w[i],
          p2: this.user?.played[m]?.t[1],
          color: this.pieChartColor[i],
        };
      }
      return {
        header: this.pieChartHeader[i],
        p1: this.user?.played[m]?.w[i],
        p2: this.user?.played[m]?.t[1],
        color: this.pieChartColor[i],
      };
    },

    numberFormat(n) {
      let num = n;
      let numStr = num.toString();
      let numLen = numStr.length;
      let newStr = "";
      for (let i = 0; i < numLen; i++) {
        if (i > 0 && (numLen - i) % 3 === 0) {
          newStr += ".";
        }
        newStr += numStr[i];
      }
      return newStr;
    },
  },
};
</script>

<style>
.pokerMode-container {
  position: absolute;
  top: 80px;
  padding: 10px 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  border-radius: 2rem;
}
.pokerMode {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10rem;
  justify-content: center;
}
.paddingYMedium {
  padding: 0.8rem 0 !important;
}
.paddingYLarge {
  padding: 1.93rem 0 !important;
}
</style>
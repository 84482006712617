<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Players</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="$store.state.players.timeSelect"
        :items="timeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Time"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="$store.state.players.pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headersOutPlayer"
      :items="all.data"
      :items-per-page="this.$store.state.players.pageItemSelect.id"
      hide-default-footer
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:item.os="{ item }">
        <v-img
          width="25"
          style="float:left"
          :src="`https://pokerface.restplay.com/assets/${item.os}.png`"
        />
        <v-img
          width="25"
          :src="`https://pokerface.restplay.com/assets/${item.snId}.png`"
        />
      </template>
      <template v-slot:item.userId="{ item }">
        <UserDetailCard
          :userId="item.userId"
          :pictureUrl="item.picture"
          :userName="item.username"
          :level="item.level"
        />
      </template>
      <template v-slot:item.bonusRewards="{ item }">
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <span
              v-bind:style="{
                color: item.bonusRewardsCount > 0 ? 'lime' : '#fff',
              }"
              v-on="on"
              >{{ item.bonusRewardsCount | formatMoney }}</span
            >
            <span>&nbsp;/ {{ item.bonusRewards.length }}</span>
          </template>
          <div v-for="(bonus, index) in item.bonusRewards" :key="index">
            {{ bonus.bonusType }}:
            <span style="color:lime;">{{ bonus.chip | formatMoney }}</span>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.user.sitInData.chip="{ item }">
        <div>{{ item.user.chipAmount | formatMoney }}</div>
        <div>{{ item.user.sitInData.chip | formatMoney }}</div>
      </template>

      <template v-slot:item.connectChip="{ item }">
        <div>{{ item.connectChip | formatMoney }}</div>
        <div
          v-bind:style="{
            color: getColor(item),
          }"
        >
          {{ item.disconnectChip | formatMoney }}
        </div>
      </template>

      <template v-slot:item.connectTime="{ item }">
        <div>{{ new Date(item.connectTime * 1000) | formatDate }}</div>
        <div v-if="item.disconnectTime">
          {{ new Date(item.disconnectTime * 1000) | formatDate }}
        </div>
      </template>
      <template v-slot:item.profitOrLoss="{ item }">
        <div
          v-bind:style="{
            color: item.profitOrLoss > 0 ? 'lime' : '#FF6D6D',
          }"
        >
          {{ item.profitOrLoss | formatMoney }}
        </div>
        <div
          v-bind:style="{
            color:
              item.disconnectChip - item.connectChip > 0 ? 'lime' : '#FF6D6D',
          }"
        >
          {{ (item.disconnectChip - item.connectChip) | formatMoney }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon big class="mr-2" @click="editItem(item)">edit</v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="all.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlayerIoDetailCard",
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  props: {
    search: {
      type: String,
      description: "search",
    },
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      all: [],
      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
      ],
      timeItems: [
        { id: -1, value: "All" },
        { id: 15, value: "Last 15 minutes" },
        { id: 30, value: "Last 30 minutes" },
        { id: 60, value: "Last 1 hour" },
        { id: 1440, value: "Last 24 hours" },
        { id: 10080, value: "Last 7 Days" },
        { id: 43200, value: "last 30 Days" },
        { id: 129600, value: "last 90 Days" },
      ],
      headersOutPlayer: [
        { text: "userId", value: "userId" },
        { text: "Platform / Login", value: "os" },
        { text: "connectChip", value: "connectChip" },
        { text: "connectTime", value: "connectTime" },
        { text: "profitOrLoss", value: "profitOrLoss" },
        { text: "Bonus Chip", value: "bonusRewards" },
        { text: "playTime", value: "playTime" },
      ],
    };
  },

  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    getColor(item) {
      if (item.disconnectChip > item.connectChip) return "lime";
      if (item.disconnectChip < item.connectChip) return "#FF6D6D";
    },
    async initialize() {
      this.loading = true;
      await this.$store.dispatch("players/getPlayerOut", {
        page: this.currentPage - 1,
        search: this.search,
      });
      this.all = this.$store.state.players.playersOut;
      this.loading = false;
    },

    formatMoney(number) {
      var ab = number.toString().split("");
      var a = "";
      ab.map((b) => {
        a += b;
      });
      // eslint-disable-next-line no-console
      console.log(a);

      return number;

      // return a;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
</style>

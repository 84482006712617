<template>
  <div id="app" :key="refleshKey">
    <apexchart
      :options="chartOptions"
      :series="series"
      type="bar"
      height="200"
      width="100%"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["modeStatistics"],
  beforeMount() {
    this.setChart(this.modeStatistics);
  },
  watch: {
    modeStatistics: {
      handler() {
        this.setChart(this.modeStatistics);
      },
      deep: true,
    },
  },
  data() {
    return {
      refleshKey: 0,
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          foreColor: "#fff",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: "top",
            },
            columnWidth: "40%",
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -30,
          style: {
            fontSize: "20px",
            colors: ["#ffffff"],
          },
        },
        xaxis: {
          categories: ["Pre-Flop", "Flop", "Turn", "River", "S.down"],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    setChart(d) {
      this.series[0].data = [];
      for (let i = 0; i < 5; i++) {
        this.series[0].data[i] =
          d.f[i] == 0 ? 0 : ((d.f[i] * 100) / d.t[1]).toFixed(1);
      }
      this.refleshKey += 1;
    },
  },
};
</script>
        
<template>
  <div class="mt-4" style="padding: 0 7rem">
    <div class="d-flex align-center achievementsSelect">
      <h2 class="pointer" @click="achievementsCategoryShow = 'success'">
        BAŞARILAR
      </h2>
      <span></span>
      <h2 class="pointer" @click="achievementsCategoryShow = 'mugs'">
        KUPALAR
      </h2>
      <span></span>
      <h2 class="pointer" @click="achievementsCategoryShow = 'rings'">
        YÜZÜK SERİSİ
      </h2>
    </div>
    <UserSuccess
      v-if="achievementsCategoryShow == 'success'"
      :userMain="userMain"
    />
    <UserMugs v-if="achievementsCategoryShow == 'mugs'" :userMain="userMain" />
    <UserRings v-if="achievementsCategoryShow == 'rings'" :user="user" />
  </div>
</template>

<script>
import UserSuccess from "./UserSuccess.vue";
import UserMugs from "./UserMugs.vue";
import UserRings from "./UserRings.vue";
export default {
  name: "UserAchievements",
  components: {
    UserSuccess,
    UserMugs,
    UserRings,
  },
  props: {
    user: {
      type: Object,
      description: "user",
    },
    userMain: {
      type: Object,
      description: "user",
    },
  },
  data() {
    return {
      achievementsCategoryShow: "success",
    };
  },
};
</script>

<style>
.achievementsSelect {
  background-color: rgba(162, 162, 162, 0.27);
  width: max-content;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin: auto;
  gap: 2rem;
}
.achievementsSelect > h2 {
  font-weight: 400;
}
.achievementsSelect > span {
  width: 2px;
  height: 30px;
  background-color: white;
}
.active {
  color: white !important;
}
</style>
<template>
  <div class="mt-8">
    <div class="d-flex justify-center" style="gap: 2rem">
      <div
        v-for="a in achievementsButton"
        :key="a.name"
        class="d-flex flex-column justify-end"
        @click="achievementsShow = a.name"
      >
        <h4 class="achievementsLevel">{{ a.level }}</h4>
        <div
          class="achievementsButtonContainer"
          :class="achievementsShow == a.name && 'activeButton'"
        >
          <h2
            v-if="a.name != 'case'"
            class="achievementsButton pointer"
            :class="
              a.name == 'bronze'
                ? 'bronze'
                : a.name == 'silver'
                ? 'silver'
                : 'gold'
            "
          >
            {{ a.name }}
          </h2>
          <div class="caseContainer pointer" v-if="a.name == 'case'">
            <img
              src="../../assets/achievements/Profile_Achievements_Main_Icon_Cases.png"
              class="case"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="d-flex flex-wrap justify-center"
        style="gap: 1.12rem; margin-top: 3rem"
      >
        <v-card
          v-for="c in achievementsShow == 'bronze'
            ? bronzeCategory
            : achievementsShow == 'silver'
            ? silverCategory
            : achievementsShow == 'gold' && goldCategory"
          :key="c.name"
          elevation="5"
          color="rgba(255, 255, 255, 0.2)"
          class="achievementsCard px-4 pt-8"
        >
          <img
            :src="require(`../../assets/achievements/achievement_${c.img}.png`)"
            width="75"
          />
          <h3 style="text-transform: uppercase">{{ c.name }}</h3>
          <div style="width: 100%" v-if="c.completed / c.status != 1">
            <v-progress-linear
              background-color="blue-grey"
              color="rgb(0, 230, 17)"
              height="7"
              rounded
              :value="(c.completed / c.status) * 100"
            ></v-progress-linear>
            <p style="text-align: center" class="mb-0">
              {{ c.completed }}/{{ c.status }}
            </p>
          </div>
          <div v-else style="position: absolute; bottom: 5px">
            <img src="../../assets/tick.png" alt="" width="50" />
          </div>
        </v-card>
        <div
          class="d-flex flex-column align-center"
          v-for="(c, i) in achievementsShow == 'case' && caseCategory"
          :key="i"
          style="position: relative"
        >
          <img
            v-if="c.completed / c.status != 1"
            :src="require(`../../assets/achievements/achievement_${c.img}.png`)"
            alt=""
            style="margin-left: 2rem"
            width="250"
          />
          <img
            v-else
            :src="
              require(`../../assets/achievements/achievement_${c.img + 1}.png`)
            "
            alt=""
            style="margin-left: 2rem; opacity: 30%"
            width="250"
          />

          <div v-if="c.completed / c.status != 1" style="width: 50%">
            <v-progress-linear
              background-color="blue-grey"
              color="rgb(0, 230, 17)"
              height="7"
              rounded
              :value="(c.completed / c.status) * 100"
            ></v-progress-linear>
            <p style="text-align: center" class="mb-0 mt-2">
              {{ c.completed }}/{{ c.status }}
            </p>
          </div>
          <div
            v-if="c.completed / c.status == 1"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <img src="../../assets/tick.png" alt="" width="100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserSuccess",
  props: {
    userMain: {
      type: Object,
      description: "userMain",
    },
  },
  data() {
    return {
      achievementsButton: [
        {
          level: "7/10",
          name: "bronze",
        },
        {
          level: "6/10",
          name: "silver",
        },
        {
          level: "5/10",
          name: "gold",
        },
        {
          level: "",
          name: "case",
        },
      ],
      achievementsShow: "bronze",
      bronzeCategory: [
        {
          img: "0",
          name: "10 El",
          completed: this.userMain?.achievements?.p[0],
          status: 10,
        },
        {
          img: "1",
          name: "Video İzle",
          completed: this.userMain?.achievements?.p[1],
          status: 100,
        },
        {
          img: "2",
          name: "Bonus Topla",
          completed: this.userMain?.achievements?.p[2],
          status: 100,
        },
        {
          img: "3",
          name: "Davet Et",
          completed: this.userMain?.achievements?.p[3],
          status: 5,
        },
        {
          img: "4",
          name: "Per",
          completed: this.userMain?.achievements?.p[4],
          status: 25,
        },
        {
          img: "5",
          name: "Emoji Gönder",
          completed: this.userMain?.achievements?.p[5],
          status: 50,
        },
        {
          img: "6",
          name: "Üst Üste",
          completed: this.userMain?.achievements?.p[6],
          status: 1,
        },
        {
          img: "7",
          name: "Rest Çek",
          completed: this.userMain?.achievements?.p[7],
          status: 25,
        },
        {
          img: "8",
          name: "Yüksek Kart",
          completed: this.userMain?.achievements?.p[8],
          status: 25,
        },
        {
          img: "9",
          name: "6 ve 9",
          completed: this.userMain?.achievements?.p[9],
          status: 1,
        },
      ],
      silverCategory: [
        {
          img: "10",
          name: "20 Arkadaş",
          completed: this.userMain?.achievements?.p[10],
          status: 20,
        },
        {
          img: "11",
          name: "Döper",
          completed: this.userMain?.achievements?.p[11],
          status: 75,
        },
        {
          img: "12",
          name: "Set",
          completed: this.userMain?.achievements?.p[12],
          status: 50,
        },
        {
          img: "13",
          name: "Seviye 50",
          completed: this.userMain?.achievements?.p[13],
          status: 50,
        },
        {
          img: "14",
          name: "Daha İyi El",
          completed: this.userMain?.achievements?.p[14],
          status: 1,
        },
        {
          img: "15",
          name: "Kent",
          completed: this.userMain?.achievements?.p[15],
          status: 50,
        },
        {
          img: "16",
          name: "Renk",
          completed: this.userMain?.achievements?.p[16],
          status: 50,
        },
        {
          img: "17",
          name: "As Papaz",
          completed: this.userMain?.achievements?.p[17],
          status: 1,
        },
        {
          img: "18",
          name: "1 Milyar",
          completed: this.userMain?.achievements?.p[18],
          status: 1,
        },
        {
          img: "19",
          name: "Renk İle Kaybet",
          completed: this.userMain?.achievements?.p[19],
          status: 10,
        },
      ],
      goldCategory: [
        {
          img: "20",
          name: "1000 El",
          completed: this.userMain?.achievements?.p[20],
          status: 1000,
        },
        {
          img: "21",
          name: "Ful",
          completed: this.userMain?.achievements?.p[21],
          status: 75,
        },
        {
          img: "22",
          name: "Seviye 100",
          completed: this.userMain?.achievements?.p[22],
          status: 100,
        },
        {
          img: "23",
          name: "Kare",
          completed: this.userMain?.achievements?.p[23],
          status: 25,
        },
        {
          img: "24",
          name: "Sıralı Renk",
          completed: this.userMain?.achievements?.p[24],
          status: 10,
        },
        {
          img: "25",
          name: "Milyar Kazanç",
          completed: this.userMain?.achievements?.p[25],
          status: 1,
        },
        {
          img: "26",
          name: "Milyarder",
          completed: this.userMain?.achievements?.p[26],
          status: 1,
        },
        {
          img: "27",
          name: "Şampiyon",
          completed: this.userMain?.achievements?.p[27],
          status: 10,
        },
        {
          img: "28",
          name: "Renk Kralı",
          completed: this.userMain?.achievements?.p[28],
          status: 1,
        },
        {
          img: "29",
          name: "Her El Tipi",
          completed: this.userMain?.achievements?.p[29],
          status: 1,
        },
      ],
      caseCategory: [
        {
          img: 30,
          value: 5,
          completed: this.completedCalculate(0, 9),
          status: 10,
        },
        {
          img: 32,
          value: 5,
          completed: this.completedCalculate(10, 19),
          status: 10,
        },
        {
          img: 34,
          value: 5,
          completed: this.completedCalculate(20, 29),
          status: 10,
        },
      ],
    };
  },
  methods: {
    completedCalculate(s, e) {
      let result = 0;
      for (let i = s; i <= e; i++) {
        if (this.userMain?.achievements?.st[i] !== 0) result += 1;
      }
      return result;
    },
  },
};
</script>

<style scope>
.achievementsButtonContainer {
  border-radius: 2rem;
  background-color: rgba(162, 162, 162, 0.27);
}
.achievementsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  height: 2rem;
  font-weight: 500;
  text-transform: uppercase;
}
.achievementsLevel {
  text-align: center;
  font-weight: 400;
}
.bronze {
  background: -webkit-linear-gradient(#eee, #a3a000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.silver {
  background: -webkit-linear-gradient(#eee, #5c5c5c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gold {
  background: -webkit-linear-gradient(#eee, #ffc800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.activeButton {
  background-color: rgba(243, 243, 243, 0.47);
}
.caseContainer {
  position: relative;
  padding: 0 3rem;
  width: 10rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: rgba(162, 162, 162, 0.27);
}
.case {
  position: absolute;
  width: 50px;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
}
.achievementsCard {
  width: 11rem;
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem !important;
}
</style>
<template>
  <div>
    <v-data-table
      :headers="headersOutPlayer"
      :items="all.data"
      :items-per-page="this.perPage"
      hide-default-footer
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:item.sn="{ item }">
        {{ item.sn | toSn }}
      </template>
      <template v-slot:item.ts="{ item }">
        {{ new Date(item.ts * 1000) | formatDateSS }}
      </template>
      <template v-slot:item.locationInfo="{ item }">
        <div style="display: flex">
          <v-avatar size="36" style="margin-right: 5px; margin-top: 3px">
            <v-img
              :src="`https://pokerface.restplay.com/flags/${item.locationInfo.country_short.toLocaleLowerCase()}.png`"
            />
          </v-avatar>
          <div>
            {{ item.locationInfo.country_long }} - {{ item.locationInfo.city }}
            <div>{{ item.deviceInfo.ipAdress.split(",")[0] }}</div>
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="all.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "AuthLogDetailCard",
  props: {
    search: {
      type: String,
    },
  },

  data() {
    return {
      currentPage: 1,
      loading: false,
      perPage: 50,
      time: { id: -1, value: "All" },
      pagination: {
        sortBy: "name",
      },
      all: [],
      headersOutPlayer: [
        { text: "userId", value: "userId" },
        { text: "sn", value: "sn" },
        { text: "LoginTime", value: "ts" },
        { text: "locationInfo", value: "locationInfo" },
        { text: "deviceInfo.os", value: "deviceInfo.os" },
        { text: "deviceInfo.Model", value: "deviceInfo.deviceModel" },
        { text: "deviceInfo.gameVersion", value: "deviceInfo.gameVersion" },
        {
          text: "deviceUniqueIdentifier",
          value: "deviceInfo.deviceUniqueIdentifier",
        },

        { text: "culture", value: "culture" },
        { text: "key", value: "key" },
        { text: "anonymousId", value: "anonymousId" },
      ],
    };
  },

  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    getTimeAgo(sec) {
      let a = new Date();
      a.setSeconds(a.getSeconds() - sec);
      return a;
    },
    async initialize() {
      this.loading = true;
      await this.$store.dispatch("logAuth/getAuthInfoByUserId", {
        page: this.currentPage - 1,
        size: this.perPage,
        userId: this.search,
      });
      this.all = this.$store.state.logAuth.all;
      this.loading = false;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

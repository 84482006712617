<template>
  <div class="my-8" style="gap: 1rem">
    <div
      v-if="this.userMain?.items.length !== 0"
      class="d-flex flex-wrap"
      style="gap: 1rem"
    >
      <v-card
        v-for="(m, i) in this.userMain?.items"
        :key="i"
        elevation="5"
        color="rgba(255, 255, 255, 0.2)"
        class="mugsCard px-4"
      >
        <img :src="require(`../../assets/mugs/${m}.png`)" width="60" />
      </v-card>
    </div>
    <div v-else>
      <h2>BU OYUNCU ŞİMDİYE KADAR HİÇ KUPA KAZANAMAMIŞTIR.</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserMugs",
  props: {
    userMain: {
      type: Object,
      description: "userMain",
    },
  },
};
</script>

<style scope>
.mugsCard {
  width: 8rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem !important;
}
</style>
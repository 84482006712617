<template>
  <div>
    <v-btn
      justify="space-around"
      color="green"
      dark
      @click="dialog = true"
      class="mb-2"
      >Admin Kick</v-btn
    >
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Kick User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Time(Seconds)"
                  v-model="time"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="adminKick">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: 0,
      dialog: false,
    };
  },
  props: {
    userId: {
      type: String,
    },
  },
  methods: {
    async adminKick() {
      await this.$store.dispatch("users/kickUser", {
        userId: this.userId,
        time: this.time,
      });
      this.dialog = false;
      this.$store.dispatch("setAlert");
    },
  },
};
</script>

<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>User</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn
        justify="space-around"
        color="primary"
        dark
        @click="restDialog = !restDialog"
        class="mb-2 mr-2"
        >RestGift</v-btn
      >
      <v-btn
        justify="space-around"
        color="red"
        dark
        @click="chatDialog = !chatDialog"
        class="mb-2 mr-2"
        >Chat Ban</v-btn
      >

      <kick-user-card-modal v-if="user" :userId="user.id" />
    </v-toolbar>
    <v-row style="margin-top: 1px; margin-bottom: 6rem">
      <v-col cols="4" lg="3">
        <v-row>
          <v-col cols="12">
            <div
              :style="{
                'background-image': `${
                  user.pictureUrl.startsWith('https')
                    ? `url(${user.pictureUrl})`
                    : user.pictureUrl.startsWith('/')
                    ? `url(https://pokerface.restplay.com${user.pictureUrl})`
                    : `url(https://pokerface.restplay.com/Avatars/${user.pictureUrl})`
                }`,
                'background-position': 'center',
                'background-size': 'cover',
                height: '500px',
                'border-radius': '1rem',
              }"
              class="pt-4 pb-4 px-2 d-flex flex-column justify-space-between"
            >
              <div class="d-flex justify-space-between">
                <v-avatar
                  size="45"
                  :class="{
                    genderMale: user.gender === 0,
                    genderFemale: user.gender === 1,
                  }"
                >
                  <v-img
                    :src="`https://pokerface.restplay.com/flags/${user.flag}.png`"
                    alt="flag"
                    max-height="40"
                    max-width="40"
                    min-height="40"
                    min-width="40"
                  />
                </v-avatar>
                <h2 class="mt-2" style="font-weight: 500">{{ user.id }}</h2>
                <div style="gap: 0.5rem" class="d-flex flex-column">
                  <v-avatar color="#616161" size="40">
                    <v-img
                      src="../../assets/icons/statistics.png"
                      alt="statistics"
                      class="pointer"
                      @click="achievementsShow = false"
                    ></v-img>
                  </v-avatar>
                  <v-avatar color="#616161" size="40">
                    <v-img
                      src="../../assets/icons/achievements.png"
                      alt="achievements"
                      class="pointer"
                      @click="achievementsShow = true"
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
              <div>
                <div
                  class="d-flex justify-center mt-12"
                  style="font-size: 1.5rem"
                >
                  <span style="color: yellow; margin-right: 0.3rem">{{
                    user.levelInfo.l
                  }}</span
                  >{{ user.userName }}
                </div>
                <div
                  class="d-flex justify-space-between mx-6 mt-2"
                  style="background-color: #00000050; border-radius: 25px"
                >
                  <div class="d-flex align-center">
                    <v-avatar color="#61616180">
                      <v-img
                        src="../../assets/icons/profile.png"
                        alt="profile"
                      ></v-img>
                    </v-avatar>
                    <span style="font-size: 1rem; padding-left: 0.5rem">{{
                      user.friendCount
                    }}</span>
                  </div>
                  <div class="d-flex align-center">
                    <span style="font-size: 1rem; padding-right: 0.5rem">{{
                      user.likeCount
                    }}</span>
                    <v-avatar color="#61616180">
                      <v-img
                        src="../../assets/icons/like.png"
                        alt="like"
                      ></v-img>
                    </v-avatar>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div
                class="mt-4 mx-4 d-flex flex-column align-center"
                style="gap: 0.5rem; border-bottom: 2px solid rgb(70, 70, 70)"
              >
                <h2>Toplam Fiş Miktarı</h2>
                <h1 style="color: rgb(0, 198, 0)" class="pb-2 px-4">
                  ${{ user.chipAmount | formatMoney }}
                </h1>
              </div>
              <div class="d-flex justify-center mx-8 mt-4" style="gap: 1rem">
                <v-card
                  style="
                    width: 200px;
                    height: 125px;
                    border-radius: 1rem;
                    background-color: rgba(66, 66, 66, 0.507);
                  "
                >
                  <v-card-text
                    class="d-flex flex-column justify-space-between px-2 align-center"
                    style="gap: 0.5rem; height: 100%"
                  >
                    <h2
                      v-if="user.vipInfo.l == 0"
                      style="height: 100px"
                      class="d-flex align-center"
                    >
                      NO VIP
                    </h2>
                    <img
                      v-if="user.vipInfo.l != 0"
                      :src="
                        require(`../../assets/vip/Vip_Card_${user.vipInfo.l}.png`)
                      "
                      width="100"
                      alt=""
                    />
                    <v-progress-linear
                      background-color="blue-grey"
                      color="rgb(200, 200, 200)"
                      height="7"
                      rounded
                      :value="(100 * user.vipInfo.l) / 7"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
                <v-card
                  style="
                    width: 200px;
                    height: 125px;
                    border-radius: 1rem;
                    background-color: rgba(66, 66, 66, 0.507);
                  "
                >
                  <v-card-text
                    class="d-flex flex-column justify-space-between px-2 align-center"
                    style="gap: 0.5rem; height: 100%"
                  >
                    <img
                      :src="
                        require(`../../assets/leagues/${user.league.i}.png`)
                      "
                      height="75"
                    />
                    <v-progress-linear
                      background-color="blue-grey"
                      color="rgb(200, 200, 200)"
                      height="7"
                      rounded
                      :value="(100 * user.league.i) / 30"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </div>
              <h2
                class="mt-4 py-3"
                style="
                  text-align: center;
                  border-bottom: 2px solid rgb(54, 54, 54);
                "
              >
                ONYX
              </h2>
              <div class="d-flex">
                <div style="width: 50%">
                  <h3
                    v-for="h in profileStatisticHeader"
                    :key="h"
                    style="
                      border-bottom: 2px solid rgb(54, 54, 54);
                      color: gray;
                    "
                    class="py-3"
                  >
                    {{ h }}
                  </h3>
                </div>
                <div style="width: 50%">
                  <h3
                    v-for="(v, i) in profileStatisticValue"
                    :key="v"
                    class="py-3 pr-2"
                    style="
                      border-bottom: 2px solid rgb(54, 54, 54);
                      text-align: right;
                    "
                    :class="{
                      redColor: i == 5 && v[0] == '-',
                      greenColor: i == 5 && v[0] != '-',
                    }"
                  >
                    {{ v }}
                  </h3>
                </div>
              </div>

              <PolarBarChart :modeStatistics="user?.played[pokerMode]" />

              <div class="d-flex justify-center" style="gap: 1.5rem">
                <div>
                  <img src="../../assets/Bronze.png" width="50" />
                  <p style="text-align: center">
                    {{ completedCalculate(0, 9) }}/10
                  </p>
                </div>
                <div>
                  <img src="../../assets/Silver.png" width="50" />
                  <p style="text-align: center">
                    {{ completedCalculate(10, 19) }}/10
                  </p>
                </div>
                <div>
                  <img src="../../assets/Gold.png" width="50" />
                  <p style="text-align: center">
                    {{ completedCalculate(20, 29) }}/10
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-card color="#fa000" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >User Detail</v-card-title
              >
              <v-card-text>
                <div>
                  <b class="white--text"> Status: </b>
                  <span>{{ user.status | toUserStatus }}</span>
                </div>
                <div>
                  <b class="white--text"> Chip: </b>
                  <span>{{ user.chipAmount | formatMoney }}</span>
                </div>
                <div>
                  <b class="white--text"> Level: </b>
                  <span>{{ user.levelInfo.l }}</span>
                </div>
                <div>
                  <b class="white--text"> Location: </b>
                  <span
                    >{{ user.locationInfo.country_long }} /
                    {{ user.locationInfo.city }}</span
                  >
                </div>

                <div>
                  <b class="white--text"> BannedReason: </b>
                  <span>{{ user.bannedReason | toBannedReason }}</span>
                </div>

                <div v-for="(item, index) in user" :key="index">
                  <div v-if="nonField.indexOf(index) > -1"></div>
                  <div v-else-if="index == 'flag'">
                    <b class="white--text"> {{ index }}: </b>
                    <v-img
                      style="display: inline-block; vertical-align: middle"
                      :src="`https://pokerface.restplay.com/flags/${item.toLocaleLowerCase()}.png`"
                      max-height="32"
                      max-width="32"
                      min-height="32"
                      min-width="32"
                    />
                  </div>
                  <div v-else-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>

                  <div v-else>
                    <div>
                      <b class="white--text"> {{ index }}: </b>
                    </div>
                    <div
                      v-for="(item2, index2) in item"
                      :key="index2"
                      style="padding-left: 10px"
                    >
                      <b class="white--text"> {{ index2 }}: </b> {{ item2 }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" lg="9" class="px-4" style="position: relative">
        <v-row>
          <v-col cols="12">
            <UserStatistics
              :user="user"
              :pokerMode="pokerMode"
              v-if="!achievementsShow"
            />
            <UserAchievements
              v-if="achievementsShow"
              :user="user"
              :userMain="userMain"
            />
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >LevelInfo</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.levelInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >Fold Ratios</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.foldRatios" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >leagues</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.league" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >Bonus</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.bonusInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >HandInfo</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.handInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >platformInfo</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.platformInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >deviceInfo</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.deviceInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >playerSettings</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.playerSettings" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >playerOnline</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.playerOnline" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" md="3" lg="2">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline font-weight-bold"
                style="display: block"
                >locationInfo</v-card-title
              >
              <v-card-text>
                <div v-for="(item, index) in user.locationInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class="white--text"> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <RestGiftCard
      v-if="user"
      :clicked="restDialog"
      :userId="user.id"
      :userName="user.userName"
      :lang="user.playerSettings.l.split('-')[0]"
      :notificationToken="user.deviceInfo.notificationToken"
    />
    <ChatBannedModalCard :clicked="chatDialog" :userId="user.id" />
  </div>
</template>

<script>
import RestGiftCard from "@/views/players/components/RestGiftCard";
import ChatBannedModalCard from "@/views/userReports/components/ChatBannedModalCard";
import KickUserCardModal from "./KickUserCardModal.vue";
import UserStatistics from "./UserStatistics.vue";
import UserAchievements from "./UserAchievements.vue";
import PolarBarChart from "../Charts/PolarBarChart.vue";

export default {
  name: "UserProfileCard",
  components: {
    RestGiftCard,
    ChatBannedModalCard,
    KickUserCardModal,
    UserStatistics,
    UserAchievements,
    PolarBarChart,
  },
  beforeMount() {
    this.profileStatistics();
  },

  data() {
    return {
      restDialog: false,
      chatDialog: false,
      nonField: [
        "league",
        "bonusInfo",
        "handInfo",
        "foldRatios",
        "levelInfo",
        "deviceInfo",
        "platformInfo",
        "playerSettings",
        "locationInfo",
        "playerOnline",
      ],
      pokerMode: "h",
      profileStatisticHeader: [
        "Oynanan Eller",
        "Oynanan Süre",
        "Sit-N-Go (Win/Play)",
        "Fiş Seviyesi",
        "İlk Giriş",
        "Kazanç (Son 5 Gün)",
      ],
      profileStatisticValue: [],
      achievementsShow: false,
    };
  },
  props: {
    user: {
      type: Object,
      description: "user",
    },
    userMain: {
      type: Object,
      description: "userMain",
    },
  },
  methods: {
    async adminKick() {
      await this.$store.dispatch("users/kickUser", this.user.id);
      this.$store.dispatch("setAlert");
    },
    profileStatistics() {
      for (let i = 0; i < 6; i++) {
        this.profileStatisticValue[i] = this.setProfileStatistics(i);
      }
    },
    setProfileStatistics(i) {
      switch (i) {
        case 0:
          return this.user.played.b[1];
        case 1: {
          let day = Math.floor(this.user.played.b[0] / 86400);
          let hour = Math.floor((this.user.played.b[0] / 3600) % 24);
          let minute = Math.floor(this.user.played.b[0] / 60) % 60;
          let second = Math.floor(this.user.played.b[0] % 60);
          if (day != 0) return `${day} Gün ${hour} Saat`;
          if (hour != 0) return `${hour} Saat ${minute} Dakika`;
          if (minute != 0) return `${minute} Dkk ${second} Sn`;
          return "-";
        }
        case 2:
          return `${this.user.played.wa}/${this.user.played.pa}`;
        case 3: {
          const prefixes = ["", "K", "M", "B", "T"];
          const exponent = Math.floor(
            Math.log10(Math.abs(this.user.played.cl)) / 3
          );
          const prefix = prefixes[exponent];
          const shortNumber = (
            this.user.played.cl / Math.pow(10, exponent * 3)
          ).toFixed(1);
          return shortNumber + " " + prefix;
        }
        case 4: {
          const unixTimestamp = this.user.registerDate;
          const date = new Date(unixTimestamp * 1000);
          const formattedDate = date.toLocaleDateString("tr-TR");
          return formattedDate;
        }
        case 5: {
          let total = 0;
          for (let i = 0; i < this.user.played.p.length; i++) {
            total += this.user.played.p[i].a;
          }
          return this.numberFormat(total);
        }

        default:
          break;
      }
    },
    numberFormat(n) {
      let num = n;
      let numStr = num.toString();
      let numLen = numStr.length;
      let control = numStr.startsWith("-") ? 1 : 0;
      let newStr = "";
      for (let i = 0; i < numLen; i++) {
        if (i > control && (numLen - i) % 3 === 0) {
          newStr += ".";
        }
        newStr += numStr[i];
      }
      return newStr;
    },
    completedCalculate(s, e) {
      let result = 0;
      for (let i = s; i <= e; i++) {
        if (this.userMain?.achievements?.st[i] !== 0) result += 1;
      }
      return result;
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.rotateTrue {
  transform: rotate(-90deg);
}
.rotateFalse {
  transform: rotate(90deg);
}
.backGround {
  background-color: rgba(0, 0, 0, 0.058);
  width: 100%;
  height: 100%;
}
.genderMale {
  border: 4px solid rgb(0, 0, 165);
}
.genderFemale {
  border: 4px solid rgb(255, 129, 171);
}
.redColor {
  color: red;
}
.greenColor {
  color: green;
}
</style>

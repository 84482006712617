<template>
  <div class="d-flex mt-8 justify-center" style="gap: 1rem; position: relative">
    <div v-for="r in ringsCategory" :key="r.name" class="ringCard">
      <img
        :src="require(`../../assets/rings/${r.name}.png`)"
        style="width: 100%; height: 100%"
      />
      <div style="width: 80%; margin: -5rem auto 0">
        <p class="mb-1">{{ r.completed }}/{{ r.status }}</p>
        <v-progress-linear
          background-color="blue-grey"
          color="rgb(0, 230, 17)"
          height="7"
          rounded
          :value="(r.completed / r.status) * 100"
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserRings",
  props: {
    user: {
      type: Object,
      description: "user",
    },
  },

  data() {
    return {
      ringsCategory: [
        {
          name: "rio",
          completed:
            (this.user?.ringSeries && this.user?.ringSeries[0]?.p) || 0,
          status:
            (this.user?.ringSeries && this.user?.ringSeries[0]?.t) || 5000,
        },
        {
          name: "macau",
          completed:
            (this.user?.ringSeries && this.user?.ringSeries[1]?.p) || 0,
          status:
            (this.user?.ringSeries && this.user?.ringSeries[1]?.p) || 5000,
        },
        {
          name: "london",
          completed:
            (this.user?.ringSeries && this.user?.ringSeries[2]?.p) || 0,
          status:
            (this.user?.ringSeries && this.user?.ringSeries[2]?.t) || 5000,
        },
        {
          name: "dubai",
          completed:
            (this.user?.ringSeries && this.user?.ringSeries[3]?.p) || 0,
          status:
            (this.user?.ringSeries && this.user?.ringSeries[3]?.t) || 5000,
        },
        {
          name: "vegas",
          completed:
            (this.user?.ringSeries && this.user?.ringSeries[4]?.p) || 0,
          status:
            (this.user?.ringSeries && this.user?.ringSeries[4]?.t) || 5000,
        },
      ],
    };
  },
};
</script>

<style>
.ringCard {
  width: 9rem;
  height: 21rem;
  border-radius: 1rem !important;
}
</style>
<template>
  <div v-if="user">
    <v-dialog v-model="dialog" scrollable fullscreen>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-tabs fixed-tabs background-color="primary" dark>
              <v-tab class="px-1" @click="setTab('userProfile')">Profile</v-tab>
              <v-tab class="px-1" @click="setTab('purchase')">Purchase</v-tab>
              <v-tab class="px-1" @click="setTab('playerIO')">Player IO</v-tab>
              <v-tab class="px-1" @click="setTab('sitBlock')">Sit Block</v-tab>
              <v-tab class="px-1" @click="setTab('chipTransfer')"
                >C. Transfer</v-tab
              >
              <v-tab class="px-1" @click="setTab('taxpay')">Tax Pay</v-tab>
              <v-tab class="px-1" @click="setTab('cashpay')">Cash Pay</v-tab>
              <v-tab class="px-1" @click="setTab('userReport')"
                >User Report</v-tab
              >
              <v-tab class="px-1" @click="setTab('gameLog')">Game Log</v-tab>
              <v-tab class="px-1" @click="setTab('bonusLog')">Bonus Log</v-tab>
              <v-tab class="px-1" @click="setTab('chatLog')">Chat Log</v-tab>
              <v-tab class="px-1" @click="setTab('authLog')">Auth Log</v-tab>
              <v-tab class="px-1" @click="setTab('voteKick')">Vote Kick</v-tab>
              <v-tab class="px-1" @click="setTab('feedback')">Feedback</v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="dialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h1 v-if="!$store.state.users.userMain.user">User Not found</h1>
              <div v-else>
                <FeedbackListCard
                  v-if="selectedTab == 'feedback'"
                  :feedbacks="feedbacks"
                  :search="userId"
                />
                <UserProfileCard
                  v-if="selectedTab == 'userProfile'"
                  :user="user"
                  :userMain="userMain"
                />
                <report-detail-card
                  v-if="selectedTab == 'userReport'"
                  :userId="userId"
                />
                <DetailCard
                  v-if="selectedTab == 'purchase'"
                  :purchases="purchases"
                  :search="userId"
                />
                <ChipDetailCard
                  v-if="selectedTab == 'chipTransfer'"
                  :search="userId"
                  :chipTransfer="chipTransfer"
                />
                <bonus-detail-card
                  v-if="selectedTab == 'bonusLog'"
                  :search="userId"
                />
                <game-log-list-card
                  v-if="selectedTab == 'gameLog'"
                  :search="userId"
                />
                <PlayerIoDetailCard
                  v-if="selectedTab == 'playerIO'"
                  :search="userId"
                />
                <chat-log-list-card
                  v-if="selectedTab == 'chatLog'"
                  :search="userId"
                />
                <sit-block-card
                  v-if="selectedTab == 'sitBlock'"
                  :search="userId"
                />
                <vote-kick-list-card
                  ref="votekick"
                  v-if="selectedTab == 'voteKick'"
                  :search="userId"
                  :hideToolbar="true"
                />
                <auth-log-detail-card
                  v-if="selectedTab == 'authLog'"
                  :search="userId"
                />
                <tax-pay-list-card
                  v-if="selectedTab == 'taxpay'"
                  :search="userId"
                />
                <cash-pay-list-card
                  v-if="selectedTab == 'cashpay'"
                  :search="userId"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailCard from "@/views/purchases/components/DetailCard";
import ChipDetailCard from "@/views/users/components/ChipDetailCard";
import FeedbackListCard from "@/views/users/components/FeedbackListCard";
import ReportDetailCard from "@/views/userReports/components/ReportDetailCard";
import UserProfileCard from "./UserProfileCard";
import PlayerIoDetailCard from "@/views/players/components/PlayerIoDetailCard";
import GameLogListCard from "../../views/gameLogs/components/GameLogListCard.vue";
import ChatLogListCard from "../../views/gameLogs/components/ChatLogListCard.vue";
import VoteKickListCard from "../../views/gameLogs/components/VoteKickListCard.vue";
import AuthLogDetailCard from "../../views/players/components/AuthLogDetailCard.vue";
import TaxPayListCard from "../../views/users/components/TaxPayListCard.vue";
import BonusDetailCard from "../../views/logs/components/BonusDetailCard.vue";
import SitBlockCard from "../../views/users/components/SitBlockCard.vue";
import CashPayListCard from "../../views/users/components/CashPayListCard.vue";

export default {
  components: {
    DetailCard,
    ChipDetailCard,
    ReportDetailCard,
    UserProfileCard,
    FeedbackListCard,
    PlayerIoDetailCard,
    GameLogListCard,
    BonusDetailCard,
    ChatLogListCard,
    VoteKickListCard,
    AuthLogDetailCard,
    TaxPayListCard,
    SitBlockCard,
    CashPayListCard,
  },
  name: "UserDetailModalCard",
  data() {
    return {
      userMain: null,
      nonField: [
        "league",
        "bonusInfo",
        "handInfo",
        "foldRatios",
        "levelInfo",
        "deviceInfo",
        "platformInfo",
        "playerSettings",
        "locationInfo",
        "playerOnline",
      ],
      progress: false,
      selectedTab: "userProfile",
      userDetail: {},
      user: null,
      purchases: {},
      chipTransfer: {},
      dialog: false,
      reportsData: {},
      feedbacks: {},
      playersOutDetail: [],
      detailHeaders: [
        { text: "From User", value: "fromUserId" },
        { text: "User", value: "userId" },
        { text: "Total Reports", value: "reportCount" },
        { text: "Reasons", value: "reasons" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "createdDate" },
        { text: "Message", value: "message" },
        { text: "Room", value: "room" },
      ],
    };
  },
  watch: {
    async clicked() {
      await this.setTab("userProfile");
    },
  },
  props: {
    clicked: {
      type: Boolean,
      description: "clicked",
    },
    userId: {
      type: String,
      default: "",
      description: "UserId",
    },
  },
  methods: {
    async setTab(item) {
      this.progress = true;
      switch (item) {
        case "userProfile":
          await this.getUserMainDetail();
          break;
        case "userReport":
          await this.getUserReportDetail();
          break;

        case "playerIO":
          this.$store.state.players.timeSelect = {
            id: -1,
            value: "All",
          };
          break;
        case "voteKick":
          break;
        default:
          break;
      }
      this.selectedTab = item;
      this.progress = false;
    },
    async getUserReportDetail() {
      this.reportsData = await this.$store.dispatch(
        "userReports/getById",
        this.userId
      );
      this.userDetail = {
        userId: this.userId,
        reports: this.$store.state.userReports.report,
      };
    },

    async getUserMainDetail() {
      await this.$store.dispatch("users/getMainById", this.userId);
      this.user = this.$store.state.users.userMain.user || {};
      this.userMain = this.$store.state.users.userMain || {};
      this.chipAmount = this.user.chipAmount;
      this.dialog = true;
    },
  },
};
</script>
<style></style>

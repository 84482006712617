<template>
  <div id="app" class="chart-container">
    <h2 style="text-align: center">{{ header }}</h2>
    <apexchart
      :options="chartOptions"
      :series="series"
      type="donut"
      height="210px"
    />
    <h1
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
      "
    >
      %{{ p1 == 0 ? 0 : ((p1 * 100) / p2).toFixed(1) }}
    </h1>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  beforeMount() {
    this.parametersCaculate();
  },
  watch: {
    p1: {
      handler() {
        this.parametersCaculate();
      },
      deep: true,
    },
    p2: {
      handler() {
        this.parametersCaculate();
      },
      deep: true,
    },
  },
  props: ["header", "p1", "p2", "color"],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 100,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        colors: this.color,
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        events: {
          beforeMount: true,
          updated: true,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    parametersCaculate() {
      this.series = [];
      this.series[0] = this.p1 == 0 ? 0 : (this.p1 * 100) / this.p2;
      this.series[1] = this.p1 == 0 ? 100 : 100 - (this.p1 * 100) / this.p2;
    },
  },
};
</script>
<style scope>
.chart-container {
  position: relative;
  width: 100%;
}
</style>
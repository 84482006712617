<template>
  <div id="app" style="position: relative" :key="refleshKey">
    <h3 style="text-align: center" v-if="show">
      Kazançlar (Son {{ modeStatistics.pr.length }} Gün)
    </h3>
    <apexchart
      :options="chartOptions"
      :series="series"
      type="bar"
      width="100%"
      height="200"
    />
    <h2
      v-if="show"
      class="profit"
      :class="{
        redColor: series[0].data.reduce((a, b) => a + b, 0) < 0,
        greenColor: series[0].data.reduce((a, b) => a + b, 0) >= 0,
      }"
    >
      ${{ series[0].data.reduce((a, b) => a + b, 0) | formatMoney }}
    </h2>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["modeStatistics", "show"],
  beforeMount() {
    this.setChart(this.modeStatistics);
  },
  watch: {
    modeStatistics: {
      handler() {
        this.setChart(this.modeStatistics);
      },
      deep: true,
    },
  },
  data() {
    return {
      refleshKey: 0,
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -10000000000000,
                  to: 0,
                  color: "#ff0000",
                },
                {
                  from: 0,
                  to: 10000000000000,
                  color: "#2ab300",
                },
              ],
            },
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#aaa",
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          followCursor: false,
          fillSeriesColor: false,
          theme: "dark",
          marker: {
            show: false,
          },

          y: {
            formatter: function (value) {
              return `$${value
                .toFixed(1)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                .replace(".0", "")}`;
            },
          },
        },
      },
    };
  },
  methods: {
    setChart(d) {
      this.series[0].data = [];
      for (let i = 0; i < d?.pr.length; i++) {
        this.series[0].data[i] = d?.pr[i]?.a || 0;
      }
      for (let y = 0; y < 7 - d?.pr.length; y++) {
        this.series[0].data.unshift(0);
      }
      this.refleshKey += 1;
    },
  },
};
</script>
<style scope>
.profit {
  text-align: center;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
}
.redColor {
  color: red;
}
.greenColor {
  color: green;
}
</style>